import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {CgWebsite} from "react-icons/cg";
import {BsGithub} from "react-icons/bs";

function ProjectCards(props) {
    return (
        <Card className="project-card-view" style={{display: 'flex', flexDirection: 'column'}}>
            <Card.Img variant="top" src={props.imgPath} alt="card-img"/>
            <Card.Body style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div>
                    <Card.Title className={"mb-5 fw-bold"} style={{fontSize: 26}}> <strong
                        className="purple">{props.title}</strong></Card.Title>
                    <Card.Text style={{textAlign: 'justify'}}>
                        {props.description}
                    </Card.Text>
                </div>
                <div className={"d-flex justify-content-center mt-5"}>
                    {!props.isBlog && props.ghLink && (

                        <Button variant="primary" href={props.ghLink} target="_blank">
                            <BsGithub/> &nbsp;
                            {props.isBlog ? "Blog" : "GitHub"}
                        </Button>
                    )}
                    {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
                    {!props.isBlog && props.demoLink && (
                        <Button
                            variant="primary"
                            href={props.demoLink}
                            target="_blank"
                            style={{marginLeft: "10px"}}
                        >
                            <CgWebsite/> &nbsp;
                            {"Website"}
                        </Button>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
}

export default ProjectCards;
